/**
 * Shows content and comparisons in a carousel based on Slick carousels.
 *
 * @module feature/Teasers
 */
import SlickCarouselBase from 'SlickCarouselBase';

/**
 * Creates a Content Carousel.
 *
 * @extends module:project/Common.SlickCarouselBase
 * @memberof module:feature/Teasers
 * @version 1.0.0
 * @author Peter van Gennip <peter.van.gennip@valtech.nl>
 */
class ContentCarousel extends SlickCarouselBase {

    /**
     * Adds element to create carousel in.
     * @param {jQueryElement} $element DOM Element to be upgraded.
     */
    constructor($element) {
        super($element);
    }

    /**
     * Initializes carousel.
     */
    init() {

        const numberOfSlides = this.$element.find('.content-carousel__item').length;

        // create config to override/extend
        const config = {
            slidesToShow: (numberOfSlides > 2) ? 3 : numberOfSlides,
            initialSlide: (numberOfSlides > 3) ? 1 : 0,
            centerMode: (numberOfSlides === 1) ? false : true,
            arrows: (numberOfSlides > 3) ? true : false,
            focusOnSelect: true,
            prevArrow: `<button type="button" class="content-carousel__btn content-carousel__btn--prev">
                            <span class= "icon icon--chevron-left" >
                                <svg role="img" title="icon: chevron left">
                                    <use xlink: ="" href="/assets/icons/common/ui.svg#chevron-left"></use>
                                </svg>
                            </span>
                        </button>`,
            nextArrow: `<button type="button" class="content-carousel__btn content-carousel__btn--next">
                            <span class= "icon icon--chevron-right" >
                                <svg role="img" title="icon: chevron right">
                                    <use xlink: ="" href="/assets/icons/common/ui.svg#chevron-right"></use>
                                </svg>
                            </span>
                        </button>`,
        };

        // slick init event
        this.$element.on('init', () => {
            switch (numberOfSlides) {
                case 1:
                case 2: {
                    this.$element.find('.slick-track > div:nth-child(1)').addClass('is-slickcurrent');
                    break;
                }
                default: {
                    this.$element.find('.slick-track > div:nth-child(2)').addClass('is-slickcurrent');
                }
            }
        });

        // slick beforeChange event
        this.$element.on('beforeChange', (e, slick, index, currentSlide) => {
            this.$element.find('.slick-track > div').removeClass('is-slickcurrent');
            $(slick.$slides.eq(currentSlide)).addClass('is-slickcurrent');
        });

        super.init(config);
    }
}

// register to Component Handler
window.ComponentHandler.register({
    constructor: ContentCarousel,
    classAsString: 'ContentCarousel',
    cssClass: 'js-content-carousel'
});

export default ContentCarousel;